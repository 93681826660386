import { tongke } from '@/axios'
class Dictionary {
  list() {
    return tongke.get('/l/dictionary')
  }
  detail(id) {
    return tongke.get(`/l/dictionary/${id}`)
  }
  /**
   * @functionName create
   * @param { Object } params
   * @param { Number } params.pid 上级id
   * @param { String } params.name  字段名
   * @param { String } params.description 名称
   * @param { String } params.value 值
   * @param { Number } params.sort 排序
   * @Description 创建字典
   * @return { Object }
   */
  create(params) {
    return tongke.post('/l/dictionary', params)
  }
  update(params) {
    return tongke.put(`/l/dictionary/${params.id}`, params)
  }
  remove(id) {
    return tongke.delete(`/l/dictionary/${id}`)
  }
}
export default new Dictionary()
