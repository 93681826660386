<template>
  <el-dialog
    append-to-body
    :title="title"
    ref="dialog"
    width="500px"
    :visible.sync="show"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :before-close="reset"
  >
    <el-form ref="form" :model="form.data" label-width="120px" :rules="form.rules">
      <el-form-item :label="form.data.pid === 0?'名称':'名称'" prop="description">
        <el-input v-model="form.data.description" clearable placeholder="请输入字段名" v-focus></el-input>
      </el-form-item>
      <el-form-item v-if="form.data.pid === 0" prop="name" label="标识：">
        <el-input v-model="form.data.name" placeholder="请输入标识" clearable></el-input>
      </el-form-item>

      <el-form-item label="字段值" prop="value" v-if="form.data.pid !== 0">
        <el-input v-model="form.data.value" clearable placeholder="请输入字段值"></el-input>
      </el-form-item>
      <el-form-item label="字段值的类型">
        <el-select v-model="form.data.value_type" placeholder="请选择字段值的类型">
          <el-option label="整数" value="int"></el-option>
          <el-option label="字符串（包括以0开头的数字）" value="string"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="字段排序: " prop="sort">
        <el-input-number v-model="form.data.sort" :min="0" :max="255" clearable />
        <el-tooltip placement="right" content="数值越大排序越靠前">
          <i class="el-icon-warning-outline tip"></i>
        </el-tooltip>
      </el-form-item>
      <div class="submit">
        <el-button size="small" @click="reset">取消</el-button>
        <el-button type="primary" size="small" @click="submit">提交</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import Dictionary from '@/api/dictionary';
export default {
  name: '',
  props: {
    title: String,
  },
  data() {
    return {
      show: false,
      form: {
        data: {
          pid: 0,
          name: '',
          sort: 1,
          description: '',
          value: 0,
          value_type: '',
        },
        loading: false,
        rules: {
          name: [{ required: true, message: '请输入字段label', trigger: 'change' }],
          description: [{ required: true, message: '请输入字段描述', trigger: 'change' }],
          value: [{ required: true, message: '请输入字段value', trigger: 'change' }],
        },
      },
      location_error: '',
      type: '',
    };
  },
  methods: {
    toggle(type, show, data) {
      this.type = type;
      !show && this.reset();
      this.show = show;
      if (!data && type === 'create') {
        console.log('创建新节点');
        this.form.data = { pid: 0, name: '', sort: 1, description: '', value: 0 };
      }
      if (data && type === 'create') {
        console.log('创建子节点');
        this.form.data.pid = data.id;
        this.form.data.name = '';
        this.form.data.value = '';
        this.form.data.value_type = data.value_type;
        if (data.has_child) {
          this.form.data.sort = data.children.length + 1;
        }
      }
      if (data && type === 'update') {
        console.log('修改节点');
        this.form.data.description = data.description;
        this.form.data.pid = data.pid;
        this.form.data.name = data.name;
        this.form.data.value = data.value;
        this.form.data.value_type = data.value_type;
        // this.form.data.value_type = 'string';
        this.form.data.sort = data.sort;
        this.form.data.id = data.id;
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let form = Object.assign(this.form.data);
          console.log(form);
          Dictionary[this.type](form)
            .then((res) => {
              console.log(res);
              this.$message.closeAll();
              this.$message.success(this.title + '成功');
            })
            .catch(() => {
              this.$message.closeAll();
              this.$message.error(this.title + '失败');
            })
            .finally(() => {
              this.loading = false;
              this.toggle(false);
              this.$emit('getList');
            });
        } else {
          console.log('还有没有填写的内容');
          return false;
        }
      });
    },
    reset(done) {
      this.$refs.form.resetFields();
      this.$emit('changeShow', false);
      if (done instanceof Function) {
        done();
      }
    },
    keydown(e) {
      if (e.key === 'Enter') {
        console.log(e.key);
        this.submit();
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keydown);
  },
  created() {},
  computed: {},
  watch: {},
  beforeDestroy() {
    window.removeEventListener('keydown', this.keydown);
  },
};
</script>

<style scoped lang="scss">
.submit {
  display: flex;
  justify-content: flex-end;
}
.el-select {
  width: 100%;
}
::v-deep .distpicker-address-wrapper {
  display: flex;
  label {
    flex: 1;
    select {
      width: 100%;
      box-sizing: border-box;
      &:focus {
        outline: none;
        border-color: $primary;
      }
    }
  }
  label:nth-of-type(-n + 2) {
    margin-right: 10px;
  }
}
.location_error {
  -webkit-text-size-adjust: 100%;
  word-break: break-all;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: '微软雅黑';
  vertical-align: baseline;
  font-style: initial;
  outline: none;
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}
::v-deep .location {
  .el-form-item__label::before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
  }
}
</style>
